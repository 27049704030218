.grid-container {
  display: grid;
}

@for $i from 1 to 12 {
  .grid-template-colums-#{$i} {
    grid-template-columns: repeat(#{$i}, 1fr);
    grid-gap: 40px;
  }
}
.grid-template-colums-2 {
  @media screen and (min-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto;
  }
  @media screen and (max-width: 400px) {
    grid-template-columns: repeat(1, 1fr);

  }
}

.grid-area-footer {
  grid-template: 100px 1fr 150px / 100px 1fr;
  grid-template-areas:
    "social content"
    "button button"
    "logo logo";
  text-align: center;
  grid-gap: 40px;
  justify-items: center;
  @media screen and (max-width: 400px) {
    grid-template-areas:
      "content content"
      "button button"
      "social social"
      "logo logo";
  }
}

.header {
  grid-area: button;
}
.social {
  margin-top: 50px;
  grid-area: social;
}
.content-price {
  margin-bottom: 45px;
  margin-top: 55px;
  grid-area: content;
  text-align: left;
  margin-right: 130px;
  @media screen and (max-width: 400px) {
    margin-left: 130px;
  }
}
.logo-hunter-cat {
  grid-area: logo;
}
#paypal-button {
  color: red;
}.slide-image{
  width: 400px;
  height: auto;
}

