@import './colors';

@for $i from 1 to 100 {
  .margin-top-#{$i}{
    margin-top: #{$i}px;
  }   
}

@for $i from 1 to 100 {
  .margin-bottom-#{$i}{
    margin-bottom: #{$i}px;
  }
}


@for $i from 1 to 100 {
  .margin-left-#{$i}{
    margin-left: #{$i}px;
  }
}


@for $i from 1 to 100 {
  .margin-right-#{$i}{
    margin-right: #{$i}px;
  }
}
