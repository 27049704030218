@import "../../sass/global";

.Navbar {
  width: 100%;
  background: -moz-linear-gradient(
    left,
    rgb(0, 0, 0) 50%,
    $yellow 50%,
    $yellow 100%
  );
  background: -webkit-gradient(
    left top,
    right top,
    $gray 50%,
    $yellow 50%,
    color-stop(100%, $yellow)
  );
  background: -webkit-linear-gradient(
    left,
    $gray 50%,
    $yellow 50%,
    $yellow 100%
  );
  background: -o-linear-gradient(left, $gray 50%, $yellow 50%, $yellow 100%);
  background: -ms-linear-gradient(left, $gray 50%, $yellow 50%, $yellow 100%);
  background: linear-gradient(to right, $gray 75%, $yellow 25%, $yellow 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#ff920a', GradientType=1 );
}

.Navbar__Logo {
  margin-left: 70%;
  display: block;
  width: auto;
  height: 150px;
  @media screen and (max-width: 400px) {
    margin-left: 39%;
  }
}

.TitleHunter {
  color: $yellow;
  font-size: 50px;
  margin-top: 15px;
}
.SubtitleHunter {
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  margin-top: 15px;
}

.center {
  text-align: left;
  margin: 15px;
  margin-left: 35 0px;
}
.NavLink {
  position: absolute;
  text-decoration: none;
  color: white;
  top: 2em;
  font-size: 1.3em;
  margin-left: 35px;
  &:hover {
    box-shadow: 3px 3px rgb(255, 255, 255);
  }

  @media screen and (max-width: 400px) {
    margin-left: 20px;
    font-size: 1em;
  }
}
.about {
  margin-left: 120px;
  @media screen and (max-width: 400px) {
    margin-left: 60px;
  }
}
.faq {
  margin-left: 280px;
  @media screen and (max-width: 400px) {
    margin-left: 170px;
  }
}
.subtitle {
  font-size: 0.9em;
  font-weight: 100;
}

.line-gold {
  width: 50%;
  height: 11px;
  background-color: $yellow;
  position: absolute;
  z-index: 5;

  @media screen and (max-width: 400px) {
    width: 60%;
  }
}
