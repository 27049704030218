@import '../../sass/_colors.scss';
.img-pressrelease {
  width: 10vw;
  height: auto;
  margin: 15px;
  @media screen and (max-width: 600px) {
    width: 30vw;
    
  }
}
.img-pressrelease-left {
  float: left;
}
/*.img-pressrelease-right {
  float: right;
  margin-top: 35px;
  @media screen and (max-width: 450px) {
    margin-top: 25px;
  }
}*/
.logo-hunter-cat-about {
  margin-top: 150px;
  left: 38%;
  grid-column: 2;
    @media screen and (max-width: 600px) {
      left: 25%;
      grid-column: 1/3;
    }
}
.grid-block{
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  background-color: $yellow;
  font-family: 'Open Sans';
}.items-center{
  justify-items: center;
}.grid-template-colums-3{
  @media screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr); 
  }
}.grid-column-2{
  @media screen and (max-width: 600px) {
    
    grid-column: 1/3;
  }
}