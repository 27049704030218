.grid-container {
  display: grid; }

.grid-template-colums-1 {
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 40px; }

.grid-template-colums-2 {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px; }

.grid-template-colums-3 {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px; }

.grid-template-colums-4 {
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px; }

.grid-template-colums-5 {
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 40px; }

.grid-template-colums-6 {
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 40px; }

.grid-template-colums-7 {
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 40px; }

.grid-template-colums-8 {
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 40px; }

.grid-template-colums-9 {
  grid-template-columns: repeat(9, 1fr);
  grid-gap: 40px; }

.grid-template-colums-10 {
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 40px; }

.grid-template-colums-11 {
  grid-template-columns: repeat(11, 1fr);
  grid-gap: 40px; }

@media screen and (min-width: 800px) {
  .grid-template-colums-2 {
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto; } }

@media screen and (max-width: 400px) {
  .grid-template-colums-2 {
    grid-template-columns: repeat(1, 1fr); } }

.grid-area-footer {
  grid-template: 100px 1fr 150px / 100px 1fr;
  grid-template-areas: "social content" "button button" "logo logo";
  text-align: center;
  grid-gap: 40px;
  justify-items: center; }
  @media screen and (max-width: 400px) {
    .grid-area-footer {
      grid-template-areas: "content content" "button button" "social social" "logo logo"; } }

.header {
  grid-area: button; }

.social {
  margin-top: 50px;
  grid-area: social; }

.content-price {
  margin-bottom: 45px;
  margin-top: 55px;
  grid-area: content;
  text-align: left;
  margin-right: 130px; }
  @media screen and (max-width: 400px) {
    .content-price {
      margin-left: 130px; } }

.logo-hunter-cat {
  grid-area: logo; }

#paypal-button {
  color: red; }

.slide-image {
  width: 400px;
  height: auto; }
