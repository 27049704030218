@import './sass/global';
* {
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: "Bebas Neue W01 Regular";
  src: url("./assets/fonts/3d9ec9a9-76a2-4cfe-b376-76e7340c3b50.eot?#iefix");
  src: url("./assets/fonts/3d9ec9a9-76a2-4cfe-b376-76e7340c3b50.eot?#iefix ")
      format("eot"),
    url("./assets/fonts/e0d6f852-5401-4bbf-9672-47a50c5c87c6.woff2")
      format("woff2"),
    url("./assets/fonts/7fedd582-6ae3-4850-be2f-4acae2e74fa5.woff")
      format("woff"),
    url("./assets/fonts/d6e08ef3-40db-4ac3-82df-f062f55a72f5.ttf")
      format("truetype");
}
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: $gray;
  color: #fff;
  font-family: "Bebas Neue W01 Regular";
}

.margin-0 {
  margin: 0;
  padding: 0;
}
