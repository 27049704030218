@import "../../sass/colors";

.card {
  margin-top: 20px;
  border-radius: 0.25rem;
  background-color: $black;
  box-shadow: 10px 10px #0f0f0fa9;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.bg-img {
  border: 4px solid $yellow;
  min-height: 180px;
  background-position: center;
  background-color: $black;
}
.img-card {
  width: 180px;
  height: 130px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  display: block;
}

.content {
  padding: 15px;
}

#img-hunter {
  padding: 0;
  width: 100%;
  height: 180px;
}
.bg-img #img-hunter {
  margin-top: 0;
}
