@import "../../sass/colors";

.wrapper {
  width: 50%;
  height: auto;
  margin-left: 15%;
  @media screen and (max-width: 400px) {
    width: 100%;
    height: auto;
    margin-left: 0%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    height: auto;
    margin-left: 0%;
  }
}
.subtitle-about {
  margin: 10px 50px 10px 50px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  text-justify: auto;
  @media screen and (max-width: 400px) {
    margin: 10px 20px 10px 20px;
  }
}
.subtitle-about-sub {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  margin: 10px 50px 10px 50px;
  @media screen and (max-width: 400px) {
    margin: 3px 30px 3px 30px;
  }
}
.link {
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  padding: 20px;
  background-color: $yellow;
  &:hover {
    background-color: $yellow2;
  }
}
.center-logo {
  margin-left: 45%;
  margin-top: 15%;

  @media screen and (max-width: 400px) {
    margin-left: 25%;
    margin-top: 15%;
  }
}
.txt-small{
  text-align: center;
  font-size: .6em;
}
.point{
  color:$yellow;
}