@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
.margin-top-1 {
  margin-top: 1px; }

.margin-top-2 {
  margin-top: 2px; }

.margin-top-3 {
  margin-top: 3px; }

.margin-top-4 {
  margin-top: 4px; }

.margin-top-5 {
  margin-top: 5px; }

.margin-top-6 {
  margin-top: 6px; }

.margin-top-7 {
  margin-top: 7px; }

.margin-top-8 {
  margin-top: 8px; }

.margin-top-9 {
  margin-top: 9px; }

.margin-top-10 {
  margin-top: 10px; }

.margin-top-11 {
  margin-top: 11px; }

.margin-top-12 {
  margin-top: 12px; }

.margin-top-13 {
  margin-top: 13px; }

.margin-top-14 {
  margin-top: 14px; }

.margin-top-15 {
  margin-top: 15px; }

.margin-top-16 {
  margin-top: 16px; }

.margin-top-17 {
  margin-top: 17px; }

.margin-top-18 {
  margin-top: 18px; }

.margin-top-19 {
  margin-top: 19px; }

.margin-top-20 {
  margin-top: 20px; }

.margin-top-21 {
  margin-top: 21px; }

.margin-top-22 {
  margin-top: 22px; }

.margin-top-23 {
  margin-top: 23px; }

.margin-top-24 {
  margin-top: 24px; }

.margin-top-25 {
  margin-top: 25px; }

.margin-top-26 {
  margin-top: 26px; }

.margin-top-27 {
  margin-top: 27px; }

.margin-top-28 {
  margin-top: 28px; }

.margin-top-29 {
  margin-top: 29px; }

.margin-top-30 {
  margin-top: 30px; }

.margin-top-31 {
  margin-top: 31px; }

.margin-top-32 {
  margin-top: 32px; }

.margin-top-33 {
  margin-top: 33px; }

.margin-top-34 {
  margin-top: 34px; }

.margin-top-35 {
  margin-top: 35px; }

.margin-top-36 {
  margin-top: 36px; }

.margin-top-37 {
  margin-top: 37px; }

.margin-top-38 {
  margin-top: 38px; }

.margin-top-39 {
  margin-top: 39px; }

.margin-top-40 {
  margin-top: 40px; }

.margin-top-41 {
  margin-top: 41px; }

.margin-top-42 {
  margin-top: 42px; }

.margin-top-43 {
  margin-top: 43px; }

.margin-top-44 {
  margin-top: 44px; }

.margin-top-45 {
  margin-top: 45px; }

.margin-top-46 {
  margin-top: 46px; }

.margin-top-47 {
  margin-top: 47px; }

.margin-top-48 {
  margin-top: 48px; }

.margin-top-49 {
  margin-top: 49px; }

.margin-top-50 {
  margin-top: 50px; }

.margin-top-51 {
  margin-top: 51px; }

.margin-top-52 {
  margin-top: 52px; }

.margin-top-53 {
  margin-top: 53px; }

.margin-top-54 {
  margin-top: 54px; }

.margin-top-55 {
  margin-top: 55px; }

.margin-top-56 {
  margin-top: 56px; }

.margin-top-57 {
  margin-top: 57px; }

.margin-top-58 {
  margin-top: 58px; }

.margin-top-59 {
  margin-top: 59px; }

.margin-top-60 {
  margin-top: 60px; }

.margin-top-61 {
  margin-top: 61px; }

.margin-top-62 {
  margin-top: 62px; }

.margin-top-63 {
  margin-top: 63px; }

.margin-top-64 {
  margin-top: 64px; }

.margin-top-65 {
  margin-top: 65px; }

.margin-top-66 {
  margin-top: 66px; }

.margin-top-67 {
  margin-top: 67px; }

.margin-top-68 {
  margin-top: 68px; }

.margin-top-69 {
  margin-top: 69px; }

.margin-top-70 {
  margin-top: 70px; }

.margin-top-71 {
  margin-top: 71px; }

.margin-top-72 {
  margin-top: 72px; }

.margin-top-73 {
  margin-top: 73px; }

.margin-top-74 {
  margin-top: 74px; }

.margin-top-75 {
  margin-top: 75px; }

.margin-top-76 {
  margin-top: 76px; }

.margin-top-77 {
  margin-top: 77px; }

.margin-top-78 {
  margin-top: 78px; }

.margin-top-79 {
  margin-top: 79px; }

.margin-top-80 {
  margin-top: 80px; }

.margin-top-81 {
  margin-top: 81px; }

.margin-top-82 {
  margin-top: 82px; }

.margin-top-83 {
  margin-top: 83px; }

.margin-top-84 {
  margin-top: 84px; }

.margin-top-85 {
  margin-top: 85px; }

.margin-top-86 {
  margin-top: 86px; }

.margin-top-87 {
  margin-top: 87px; }

.margin-top-88 {
  margin-top: 88px; }

.margin-top-89 {
  margin-top: 89px; }

.margin-top-90 {
  margin-top: 90px; }

.margin-top-91 {
  margin-top: 91px; }

.margin-top-92 {
  margin-top: 92px; }

.margin-top-93 {
  margin-top: 93px; }

.margin-top-94 {
  margin-top: 94px; }

.margin-top-95 {
  margin-top: 95px; }

.margin-top-96 {
  margin-top: 96px; }

.margin-top-97 {
  margin-top: 97px; }

.margin-top-98 {
  margin-top: 98px; }

.margin-top-99 {
  margin-top: 99px; }

.margin-bottom-1 {
  margin-bottom: 1px; }

.margin-bottom-2 {
  margin-bottom: 2px; }

.margin-bottom-3 {
  margin-bottom: 3px; }

.margin-bottom-4 {
  margin-bottom: 4px; }

.margin-bottom-5 {
  margin-bottom: 5px; }

.margin-bottom-6 {
  margin-bottom: 6px; }

.margin-bottom-7 {
  margin-bottom: 7px; }

.margin-bottom-8 {
  margin-bottom: 8px; }

.margin-bottom-9 {
  margin-bottom: 9px; }

.margin-bottom-10 {
  margin-bottom: 10px; }

.margin-bottom-11 {
  margin-bottom: 11px; }

.margin-bottom-12 {
  margin-bottom: 12px; }

.margin-bottom-13 {
  margin-bottom: 13px; }

.margin-bottom-14 {
  margin-bottom: 14px; }

.margin-bottom-15 {
  margin-bottom: 15px; }

.margin-bottom-16 {
  margin-bottom: 16px; }

.margin-bottom-17 {
  margin-bottom: 17px; }

.margin-bottom-18 {
  margin-bottom: 18px; }

.margin-bottom-19 {
  margin-bottom: 19px; }

.margin-bottom-20 {
  margin-bottom: 20px; }

.margin-bottom-21 {
  margin-bottom: 21px; }

.margin-bottom-22 {
  margin-bottom: 22px; }

.margin-bottom-23 {
  margin-bottom: 23px; }

.margin-bottom-24 {
  margin-bottom: 24px; }

.margin-bottom-25 {
  margin-bottom: 25px; }

.margin-bottom-26 {
  margin-bottom: 26px; }

.margin-bottom-27 {
  margin-bottom: 27px; }

.margin-bottom-28 {
  margin-bottom: 28px; }

.margin-bottom-29 {
  margin-bottom: 29px; }

.margin-bottom-30 {
  margin-bottom: 30px; }

.margin-bottom-31 {
  margin-bottom: 31px; }

.margin-bottom-32 {
  margin-bottom: 32px; }

.margin-bottom-33 {
  margin-bottom: 33px; }

.margin-bottom-34 {
  margin-bottom: 34px; }

.margin-bottom-35 {
  margin-bottom: 35px; }

.margin-bottom-36 {
  margin-bottom: 36px; }

.margin-bottom-37 {
  margin-bottom: 37px; }

.margin-bottom-38 {
  margin-bottom: 38px; }

.margin-bottom-39 {
  margin-bottom: 39px; }

.margin-bottom-40 {
  margin-bottom: 40px; }

.margin-bottom-41 {
  margin-bottom: 41px; }

.margin-bottom-42 {
  margin-bottom: 42px; }

.margin-bottom-43 {
  margin-bottom: 43px; }

.margin-bottom-44 {
  margin-bottom: 44px; }

.margin-bottom-45 {
  margin-bottom: 45px; }

.margin-bottom-46 {
  margin-bottom: 46px; }

.margin-bottom-47 {
  margin-bottom: 47px; }

.margin-bottom-48 {
  margin-bottom: 48px; }

.margin-bottom-49 {
  margin-bottom: 49px; }

.margin-bottom-50 {
  margin-bottom: 50px; }

.margin-bottom-51 {
  margin-bottom: 51px; }

.margin-bottom-52 {
  margin-bottom: 52px; }

.margin-bottom-53 {
  margin-bottom: 53px; }

.margin-bottom-54 {
  margin-bottom: 54px; }

.margin-bottom-55 {
  margin-bottom: 55px; }

.margin-bottom-56 {
  margin-bottom: 56px; }

.margin-bottom-57 {
  margin-bottom: 57px; }

.margin-bottom-58 {
  margin-bottom: 58px; }

.margin-bottom-59 {
  margin-bottom: 59px; }

.margin-bottom-60 {
  margin-bottom: 60px; }

.margin-bottom-61 {
  margin-bottom: 61px; }

.margin-bottom-62 {
  margin-bottom: 62px; }

.margin-bottom-63 {
  margin-bottom: 63px; }

.margin-bottom-64 {
  margin-bottom: 64px; }

.margin-bottom-65 {
  margin-bottom: 65px; }

.margin-bottom-66 {
  margin-bottom: 66px; }

.margin-bottom-67 {
  margin-bottom: 67px; }

.margin-bottom-68 {
  margin-bottom: 68px; }

.margin-bottom-69 {
  margin-bottom: 69px; }

.margin-bottom-70 {
  margin-bottom: 70px; }

.margin-bottom-71 {
  margin-bottom: 71px; }

.margin-bottom-72 {
  margin-bottom: 72px; }

.margin-bottom-73 {
  margin-bottom: 73px; }

.margin-bottom-74 {
  margin-bottom: 74px; }

.margin-bottom-75 {
  margin-bottom: 75px; }

.margin-bottom-76 {
  margin-bottom: 76px; }

.margin-bottom-77 {
  margin-bottom: 77px; }

.margin-bottom-78 {
  margin-bottom: 78px; }

.margin-bottom-79 {
  margin-bottom: 79px; }

.margin-bottom-80 {
  margin-bottom: 80px; }

.margin-bottom-81 {
  margin-bottom: 81px; }

.margin-bottom-82 {
  margin-bottom: 82px; }

.margin-bottom-83 {
  margin-bottom: 83px; }

.margin-bottom-84 {
  margin-bottom: 84px; }

.margin-bottom-85 {
  margin-bottom: 85px; }

.margin-bottom-86 {
  margin-bottom: 86px; }

.margin-bottom-87 {
  margin-bottom: 87px; }

.margin-bottom-88 {
  margin-bottom: 88px; }

.margin-bottom-89 {
  margin-bottom: 89px; }

.margin-bottom-90 {
  margin-bottom: 90px; }

.margin-bottom-91 {
  margin-bottom: 91px; }

.margin-bottom-92 {
  margin-bottom: 92px; }

.margin-bottom-93 {
  margin-bottom: 93px; }

.margin-bottom-94 {
  margin-bottom: 94px; }

.margin-bottom-95 {
  margin-bottom: 95px; }

.margin-bottom-96 {
  margin-bottom: 96px; }

.margin-bottom-97 {
  margin-bottom: 97px; }

.margin-bottom-98 {
  margin-bottom: 98px; }

.margin-bottom-99 {
  margin-bottom: 99px; }

.margin-left-1 {
  margin-left: 1px; }

.margin-left-2 {
  margin-left: 2px; }

.margin-left-3 {
  margin-left: 3px; }

.margin-left-4 {
  margin-left: 4px; }

.margin-left-5 {
  margin-left: 5px; }

.margin-left-6 {
  margin-left: 6px; }

.margin-left-7 {
  margin-left: 7px; }

.margin-left-8 {
  margin-left: 8px; }

.margin-left-9 {
  margin-left: 9px; }

.margin-left-10 {
  margin-left: 10px; }

.margin-left-11 {
  margin-left: 11px; }

.margin-left-12 {
  margin-left: 12px; }

.margin-left-13 {
  margin-left: 13px; }

.margin-left-14 {
  margin-left: 14px; }

.margin-left-15 {
  margin-left: 15px; }

.margin-left-16 {
  margin-left: 16px; }

.margin-left-17 {
  margin-left: 17px; }

.margin-left-18 {
  margin-left: 18px; }

.margin-left-19 {
  margin-left: 19px; }

.margin-left-20 {
  margin-left: 20px; }

.margin-left-21 {
  margin-left: 21px; }

.margin-left-22 {
  margin-left: 22px; }

.margin-left-23 {
  margin-left: 23px; }

.margin-left-24 {
  margin-left: 24px; }

.margin-left-25 {
  margin-left: 25px; }

.margin-left-26 {
  margin-left: 26px; }

.margin-left-27 {
  margin-left: 27px; }

.margin-left-28 {
  margin-left: 28px; }

.margin-left-29 {
  margin-left: 29px; }

.margin-left-30 {
  margin-left: 30px; }

.margin-left-31 {
  margin-left: 31px; }

.margin-left-32 {
  margin-left: 32px; }

.margin-left-33 {
  margin-left: 33px; }

.margin-left-34 {
  margin-left: 34px; }

.margin-left-35 {
  margin-left: 35px; }

.margin-left-36 {
  margin-left: 36px; }

.margin-left-37 {
  margin-left: 37px; }

.margin-left-38 {
  margin-left: 38px; }

.margin-left-39 {
  margin-left: 39px; }

.margin-left-40 {
  margin-left: 40px; }

.margin-left-41 {
  margin-left: 41px; }

.margin-left-42 {
  margin-left: 42px; }

.margin-left-43 {
  margin-left: 43px; }

.margin-left-44 {
  margin-left: 44px; }

.margin-left-45 {
  margin-left: 45px; }

.margin-left-46 {
  margin-left: 46px; }

.margin-left-47 {
  margin-left: 47px; }

.margin-left-48 {
  margin-left: 48px; }

.margin-left-49 {
  margin-left: 49px; }

.margin-left-50 {
  margin-left: 50px; }

.margin-left-51 {
  margin-left: 51px; }

.margin-left-52 {
  margin-left: 52px; }

.margin-left-53 {
  margin-left: 53px; }

.margin-left-54 {
  margin-left: 54px; }

.margin-left-55 {
  margin-left: 55px; }

.margin-left-56 {
  margin-left: 56px; }

.margin-left-57 {
  margin-left: 57px; }

.margin-left-58 {
  margin-left: 58px; }

.margin-left-59 {
  margin-left: 59px; }

.margin-left-60 {
  margin-left: 60px; }

.margin-left-61 {
  margin-left: 61px; }

.margin-left-62 {
  margin-left: 62px; }

.margin-left-63 {
  margin-left: 63px; }

.margin-left-64 {
  margin-left: 64px; }

.margin-left-65 {
  margin-left: 65px; }

.margin-left-66 {
  margin-left: 66px; }

.margin-left-67 {
  margin-left: 67px; }

.margin-left-68 {
  margin-left: 68px; }

.margin-left-69 {
  margin-left: 69px; }

.margin-left-70 {
  margin-left: 70px; }

.margin-left-71 {
  margin-left: 71px; }

.margin-left-72 {
  margin-left: 72px; }

.margin-left-73 {
  margin-left: 73px; }

.margin-left-74 {
  margin-left: 74px; }

.margin-left-75 {
  margin-left: 75px; }

.margin-left-76 {
  margin-left: 76px; }

.margin-left-77 {
  margin-left: 77px; }

.margin-left-78 {
  margin-left: 78px; }

.margin-left-79 {
  margin-left: 79px; }

.margin-left-80 {
  margin-left: 80px; }

.margin-left-81 {
  margin-left: 81px; }

.margin-left-82 {
  margin-left: 82px; }

.margin-left-83 {
  margin-left: 83px; }

.margin-left-84 {
  margin-left: 84px; }

.margin-left-85 {
  margin-left: 85px; }

.margin-left-86 {
  margin-left: 86px; }

.margin-left-87 {
  margin-left: 87px; }

.margin-left-88 {
  margin-left: 88px; }

.margin-left-89 {
  margin-left: 89px; }

.margin-left-90 {
  margin-left: 90px; }

.margin-left-91 {
  margin-left: 91px; }

.margin-left-92 {
  margin-left: 92px; }

.margin-left-93 {
  margin-left: 93px; }

.margin-left-94 {
  margin-left: 94px; }

.margin-left-95 {
  margin-left: 95px; }

.margin-left-96 {
  margin-left: 96px; }

.margin-left-97 {
  margin-left: 97px; }

.margin-left-98 {
  margin-left: 98px; }

.margin-left-99 {
  margin-left: 99px; }

.margin-right-1 {
  margin-right: 1px; }

.margin-right-2 {
  margin-right: 2px; }

.margin-right-3 {
  margin-right: 3px; }

.margin-right-4 {
  margin-right: 4px; }

.margin-right-5 {
  margin-right: 5px; }

.margin-right-6 {
  margin-right: 6px; }

.margin-right-7 {
  margin-right: 7px; }

.margin-right-8 {
  margin-right: 8px; }

.margin-right-9 {
  margin-right: 9px; }

.margin-right-10 {
  margin-right: 10px; }

.margin-right-11 {
  margin-right: 11px; }

.margin-right-12 {
  margin-right: 12px; }

.margin-right-13 {
  margin-right: 13px; }

.margin-right-14 {
  margin-right: 14px; }

.margin-right-15 {
  margin-right: 15px; }

.margin-right-16 {
  margin-right: 16px; }

.margin-right-17 {
  margin-right: 17px; }

.margin-right-18 {
  margin-right: 18px; }

.margin-right-19 {
  margin-right: 19px; }

.margin-right-20 {
  margin-right: 20px; }

.margin-right-21 {
  margin-right: 21px; }

.margin-right-22 {
  margin-right: 22px; }

.margin-right-23 {
  margin-right: 23px; }

.margin-right-24 {
  margin-right: 24px; }

.margin-right-25 {
  margin-right: 25px; }

.margin-right-26 {
  margin-right: 26px; }

.margin-right-27 {
  margin-right: 27px; }

.margin-right-28 {
  margin-right: 28px; }

.margin-right-29 {
  margin-right: 29px; }

.margin-right-30 {
  margin-right: 30px; }

.margin-right-31 {
  margin-right: 31px; }

.margin-right-32 {
  margin-right: 32px; }

.margin-right-33 {
  margin-right: 33px; }

.margin-right-34 {
  margin-right: 34px; }

.margin-right-35 {
  margin-right: 35px; }

.margin-right-36 {
  margin-right: 36px; }

.margin-right-37 {
  margin-right: 37px; }

.margin-right-38 {
  margin-right: 38px; }

.margin-right-39 {
  margin-right: 39px; }

.margin-right-40 {
  margin-right: 40px; }

.margin-right-41 {
  margin-right: 41px; }

.margin-right-42 {
  margin-right: 42px; }

.margin-right-43 {
  margin-right: 43px; }

.margin-right-44 {
  margin-right: 44px; }

.margin-right-45 {
  margin-right: 45px; }

.margin-right-46 {
  margin-right: 46px; }

.margin-right-47 {
  margin-right: 47px; }

.margin-right-48 {
  margin-right: 48px; }

.margin-right-49 {
  margin-right: 49px; }

.margin-right-50 {
  margin-right: 50px; }

.margin-right-51 {
  margin-right: 51px; }

.margin-right-52 {
  margin-right: 52px; }

.margin-right-53 {
  margin-right: 53px; }

.margin-right-54 {
  margin-right: 54px; }

.margin-right-55 {
  margin-right: 55px; }

.margin-right-56 {
  margin-right: 56px; }

.margin-right-57 {
  margin-right: 57px; }

.margin-right-58 {
  margin-right: 58px; }

.margin-right-59 {
  margin-right: 59px; }

.margin-right-60 {
  margin-right: 60px; }

.margin-right-61 {
  margin-right: 61px; }

.margin-right-62 {
  margin-right: 62px; }

.margin-right-63 {
  margin-right: 63px; }

.margin-right-64 {
  margin-right: 64px; }

.margin-right-65 {
  margin-right: 65px; }

.margin-right-66 {
  margin-right: 66px; }

.margin-right-67 {
  margin-right: 67px; }

.margin-right-68 {
  margin-right: 68px; }

.margin-right-69 {
  margin-right: 69px; }

.margin-right-70 {
  margin-right: 70px; }

.margin-right-71 {
  margin-right: 71px; }

.margin-right-72 {
  margin-right: 72px; }

.margin-right-73 {
  margin-right: 73px; }

.margin-right-74 {
  margin-right: 74px; }

.margin-right-75 {
  margin-right: 75px; }

.margin-right-76 {
  margin-right: 76px; }

.margin-right-77 {
  margin-right: 77px; }

.margin-right-78 {
  margin-right: 78px; }

.margin-right-79 {
  margin-right: 79px; }

.margin-right-80 {
  margin-right: 80px; }

.margin-right-81 {
  margin-right: 81px; }

.margin-right-82 {
  margin-right: 82px; }

.margin-right-83 {
  margin-right: 83px; }

.margin-right-84 {
  margin-right: 84px; }

.margin-right-85 {
  margin-right: 85px; }

.margin-right-86 {
  margin-right: 86px; }

.margin-right-87 {
  margin-right: 87px; }

.margin-right-88 {
  margin-right: 88px; }

.margin-right-89 {
  margin-right: 89px; }

.margin-right-90 {
  margin-right: 90px; }

.margin-right-91 {
  margin-right: 91px; }

.margin-right-92 {
  margin-right: 92px; }

.margin-right-93 {
  margin-right: 93px; }

.margin-right-94 {
  margin-right: 94px; }

.margin-right-95 {
  margin-right: 95px; }

.margin-right-96 {
  margin-right: 96px; }

.margin-right-97 {
  margin-right: 97px; }

.margin-right-98 {
  margin-right: 98px; }

.margin-right-99 {
  margin-right: 99px; }

.Navbar {
  width: 100%;
  background: -webkit-gradient(left top, right top, #000000 50%, #9A6629 50%, color-stop(100%, #9A6629));
  background: -webkit-gradient(linear, left top, right top, color-stop(75%, #000000), color-stop(25%, #9A6629), to(#9A6629));
  background: linear-gradient(to right, #000000 75%, #9A6629 25%, #9A6629 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fafafa', endColorstr='#ff920a', GradientType=1 ); }

.Navbar__Logo {
  margin-left: 70%;
  display: block;
  width: auto;
  height: 150px; }
  @media screen and (max-width: 400px) {
    .Navbar__Logo {
      margin-left: 39%; } }

.TitleHunter {
  color: #9A6629;
  font-size: 50px;
  margin-top: 15px; }

.SubtitleHunter {
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  margin-top: 15px; }

.center {
  text-align: left;
  margin: 15px;
  margin-left: 35 0px; }

.NavLink {
  position: absolute;
  text-decoration: none;
  color: white;
  top: 2em;
  font-size: 1.3em;
  margin-left: 35px; }
  .NavLink:hover {
    box-shadow: 3px 3px white; }
  @media screen and (max-width: 400px) {
    .NavLink {
      margin-left: 20px;
      font-size: 1em; } }

.about {
  margin-left: 120px; }
  @media screen and (max-width: 400px) {
    .about {
      margin-left: 60px; } }

.faq {
  margin-left: 280px; }
  @media screen and (max-width: 400px) {
    .faq {
      margin-left: 170px; } }

.subtitle {
  font-size: 0.9em;
  font-weight: 100; }

.line-gold {
  width: 50%;
  height: 11px;
  background-color: #9A6629;
  position: absolute;
  z-index: 5; }
  @media screen and (max-width: 400px) {
    .line-gold {
      width: 60%; } }

.grid-container {
  display: grid; }

.grid-template-colums-1 {
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 40px; }

.grid-template-colums-2 {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px; }

.grid-template-colums-3 {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px; }

.grid-template-colums-4 {
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px; }

.grid-template-colums-5 {
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 40px; }

.grid-template-colums-6 {
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 40px; }

.grid-template-colums-7 {
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 40px; }

.grid-template-colums-8 {
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 40px; }

.grid-template-colums-9 {
  grid-template-columns: repeat(9, 1fr);
  grid-gap: 40px; }

.grid-template-colums-10 {
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 40px; }

.grid-template-colums-11 {
  grid-template-columns: repeat(11, 1fr);
  grid-gap: 40px; }

@media screen and (min-width: 800px) {
  .grid-template-colums-2 {
    grid-template-columns: repeat(2, 1fr);
    margin: 0 auto; } }

@media screen and (max-width: 400px) {
  .grid-template-colums-2 {
    grid-template-columns: repeat(1, 1fr); } }

.grid-area-footer {
  grid-template: 100px 1fr 150px / 100px 1fr;
  grid-template-areas: "social content" "button button" "logo logo";
  text-align: center;
  grid-gap: 40px;
  justify-items: center; }
  @media screen and (max-width: 400px) {
    .grid-area-footer {
      grid-template-areas: "content content" "button button" "social social" "logo logo"; } }

.header {
  grid-area: button; }

.social {
  margin-top: 50px;
  grid-area: social; }

.content-price {
  margin-bottom: 45px;
  margin-top: 55px;
  grid-area: content;
  text-align: left;
  margin-right: 130px; }
  @media screen and (max-width: 400px) {
    .content-price {
      margin-left: 130px; } }

.logo-hunter-cat {
  grid-area: logo; }

#paypal-button {
  color: red; }

.slide-image {
  width: 400px;
  height: auto; }

.card {
  margin-top: 20px;
  border-radius: 0.25rem;
  background-color: #080808;
  box-shadow: 10px 10px #0f0f0fa9;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px; }

.bg-img {
  border: 4px solid #9A6629;
  min-height: 180px;
  background-position: center;
  background-color: #080808; }

.img-card {
  width: 180px;
  height: 130px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  display: block; }

.content {
  padding: 15px; }

#img-hunter {
  padding: 0;
  width: 100%;
  height: 180px; }

.bg-img #img-hunter {
  margin-top: 0; }

.btn-primary {
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: bold;
  background: linear-gradient(290deg, #be7c31, #9A6629);
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 30px;
  padding-right: 30px;
  color: #000;
  text-decoration: none;
  width: 220px;
  height: 200px;
  border-radius: 30px; }

.hunter-logo {
  width: 150px;
  height: auto; }

.social-media {
  display: block;
  margin: 20px;
  width: 30px;
  height: auto; }
  @media screen and (max-width: 400px) {
    .social-media {
      display: inline-block; } }

.icon-facebook {
  width: 20px; }

.footer-p {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 100;
  letter-spacing: 0px;
  text-align: center; }
  @media screen and (max-width: 600px) {
    .footer-p {
      margin-left: 0; } }

.price-footer {
  font-size: 80px;
  letter-spacing: -1px; }

#zoid-paypal-button-cef908d29b {
  background: red; }

.p-xs {
  margin-top: 10px;
  text-align: center;
  font-size: 40px; }
  .p-xs:before {
    position: absolute;
    content: "----------";
    -webkit-transform: rotate(15deg);
            transform: rotate(15deg);
    color: red; }

.paypal-button {
  margin-top: 45px; }

.button-order-now {
  background-color: #9A6629;
  color: #fff;
  font-size: 25px;
  padding: 15px 50px 15px 50px;
  text-decoration: none; }

.txt-small {
  color: #fff; }

.header {
  width: 400px; }
  @media screen and (max-width: 450px) {
    .header {
      width: 250px; } }

.small {
  font-size: 15px; }

.img-pressrelease {
  width: 10vw;
  height: auto;
  margin: 15px; }
  @media screen and (max-width: 600px) {
    .img-pressrelease {
      width: 30vw; } }

.img-pressrelease-left {
  float: left; }

/*.img-pressrelease-right {
  float: right;
  margin-top: 35px;
  @media screen and (max-width: 450px) {
    margin-top: 25px;
  }
}*/
.logo-hunter-cat-about {
  margin-top: 150px;
  left: 38%;
  grid-column: 2; }
  @media screen and (max-width: 600px) {
    .logo-hunter-cat-about {
      left: 25%;
      grid-column: 1/3; } }

.grid-block {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  background-color: #9A6629;
  font-family: 'Open Sans'; }

.items-center {
  justify-items: center; }

@media screen and (max-width: 600px) {
  .grid-template-colums-3 {
    grid-template-columns: repeat(2, 1fr); } }

@media screen and (max-width: 600px) {
  .grid-column-2 {
    grid-column: 1/3; } }

.wrapper {
  width: 50%;
  height: auto;
  margin-left: 15%; }
  @media screen and (max-width: 400px) {
    .wrapper {
      width: 100%;
      height: auto;
      margin-left: 0%; } }
  @media screen and (max-width: 600px) {
    .wrapper {
      width: 100%;
      height: auto;
      margin-left: 0%; } }

.subtitle-about {
  margin: 10px 50px 10px 50px;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  text-justify: auto; }
  @media screen and (max-width: 400px) {
    .subtitle-about {
      margin: 10px 20px 10px 20px; } }

.subtitle-about-sub {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  margin: 10px 50px 10px 50px; }
  @media screen and (max-width: 400px) {
    .subtitle-about-sub {
      margin: 3px 30px 3px 30px; } }

.link {
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  padding: 20px;
  background-color: #9A6629; }
  .link:hover {
    background-color: #be7c31; }

.center-logo {
  margin-left: 45%;
  margin-top: 15%; }
  @media screen and (max-width: 400px) {
    .center-logo {
      margin-left: 25%;
      margin-top: 15%; } }

.txt-small {
  text-align: center;
  font-size: .6em; }

.point {
  color: #9A6629; }

.margin-top-1 {
  margin-top: 1px; }

.margin-top-2 {
  margin-top: 2px; }

.margin-top-3 {
  margin-top: 3px; }

.margin-top-4 {
  margin-top: 4px; }

.margin-top-5 {
  margin-top: 5px; }

.margin-top-6 {
  margin-top: 6px; }

.margin-top-7 {
  margin-top: 7px; }

.margin-top-8 {
  margin-top: 8px; }

.margin-top-9 {
  margin-top: 9px; }

.margin-top-10 {
  margin-top: 10px; }

.margin-top-11 {
  margin-top: 11px; }

.margin-top-12 {
  margin-top: 12px; }

.margin-top-13 {
  margin-top: 13px; }

.margin-top-14 {
  margin-top: 14px; }

.margin-top-15 {
  margin-top: 15px; }

.margin-top-16 {
  margin-top: 16px; }

.margin-top-17 {
  margin-top: 17px; }

.margin-top-18 {
  margin-top: 18px; }

.margin-top-19 {
  margin-top: 19px; }

.margin-top-20 {
  margin-top: 20px; }

.margin-top-21 {
  margin-top: 21px; }

.margin-top-22 {
  margin-top: 22px; }

.margin-top-23 {
  margin-top: 23px; }

.margin-top-24 {
  margin-top: 24px; }

.margin-top-25 {
  margin-top: 25px; }

.margin-top-26 {
  margin-top: 26px; }

.margin-top-27 {
  margin-top: 27px; }

.margin-top-28 {
  margin-top: 28px; }

.margin-top-29 {
  margin-top: 29px; }

.margin-top-30 {
  margin-top: 30px; }

.margin-top-31 {
  margin-top: 31px; }

.margin-top-32 {
  margin-top: 32px; }

.margin-top-33 {
  margin-top: 33px; }

.margin-top-34 {
  margin-top: 34px; }

.margin-top-35 {
  margin-top: 35px; }

.margin-top-36 {
  margin-top: 36px; }

.margin-top-37 {
  margin-top: 37px; }

.margin-top-38 {
  margin-top: 38px; }

.margin-top-39 {
  margin-top: 39px; }

.margin-top-40 {
  margin-top: 40px; }

.margin-top-41 {
  margin-top: 41px; }

.margin-top-42 {
  margin-top: 42px; }

.margin-top-43 {
  margin-top: 43px; }

.margin-top-44 {
  margin-top: 44px; }

.margin-top-45 {
  margin-top: 45px; }

.margin-top-46 {
  margin-top: 46px; }

.margin-top-47 {
  margin-top: 47px; }

.margin-top-48 {
  margin-top: 48px; }

.margin-top-49 {
  margin-top: 49px; }

.margin-top-50 {
  margin-top: 50px; }

.margin-top-51 {
  margin-top: 51px; }

.margin-top-52 {
  margin-top: 52px; }

.margin-top-53 {
  margin-top: 53px; }

.margin-top-54 {
  margin-top: 54px; }

.margin-top-55 {
  margin-top: 55px; }

.margin-top-56 {
  margin-top: 56px; }

.margin-top-57 {
  margin-top: 57px; }

.margin-top-58 {
  margin-top: 58px; }

.margin-top-59 {
  margin-top: 59px; }

.margin-top-60 {
  margin-top: 60px; }

.margin-top-61 {
  margin-top: 61px; }

.margin-top-62 {
  margin-top: 62px; }

.margin-top-63 {
  margin-top: 63px; }

.margin-top-64 {
  margin-top: 64px; }

.margin-top-65 {
  margin-top: 65px; }

.margin-top-66 {
  margin-top: 66px; }

.margin-top-67 {
  margin-top: 67px; }

.margin-top-68 {
  margin-top: 68px; }

.margin-top-69 {
  margin-top: 69px; }

.margin-top-70 {
  margin-top: 70px; }

.margin-top-71 {
  margin-top: 71px; }

.margin-top-72 {
  margin-top: 72px; }

.margin-top-73 {
  margin-top: 73px; }

.margin-top-74 {
  margin-top: 74px; }

.margin-top-75 {
  margin-top: 75px; }

.margin-top-76 {
  margin-top: 76px; }

.margin-top-77 {
  margin-top: 77px; }

.margin-top-78 {
  margin-top: 78px; }

.margin-top-79 {
  margin-top: 79px; }

.margin-top-80 {
  margin-top: 80px; }

.margin-top-81 {
  margin-top: 81px; }

.margin-top-82 {
  margin-top: 82px; }

.margin-top-83 {
  margin-top: 83px; }

.margin-top-84 {
  margin-top: 84px; }

.margin-top-85 {
  margin-top: 85px; }

.margin-top-86 {
  margin-top: 86px; }

.margin-top-87 {
  margin-top: 87px; }

.margin-top-88 {
  margin-top: 88px; }

.margin-top-89 {
  margin-top: 89px; }

.margin-top-90 {
  margin-top: 90px; }

.margin-top-91 {
  margin-top: 91px; }

.margin-top-92 {
  margin-top: 92px; }

.margin-top-93 {
  margin-top: 93px; }

.margin-top-94 {
  margin-top: 94px; }

.margin-top-95 {
  margin-top: 95px; }

.margin-top-96 {
  margin-top: 96px; }

.margin-top-97 {
  margin-top: 97px; }

.margin-top-98 {
  margin-top: 98px; }

.margin-top-99 {
  margin-top: 99px; }

.margin-bottom-1 {
  margin-bottom: 1px; }

.margin-bottom-2 {
  margin-bottom: 2px; }

.margin-bottom-3 {
  margin-bottom: 3px; }

.margin-bottom-4 {
  margin-bottom: 4px; }

.margin-bottom-5 {
  margin-bottom: 5px; }

.margin-bottom-6 {
  margin-bottom: 6px; }

.margin-bottom-7 {
  margin-bottom: 7px; }

.margin-bottom-8 {
  margin-bottom: 8px; }

.margin-bottom-9 {
  margin-bottom: 9px; }

.margin-bottom-10 {
  margin-bottom: 10px; }

.margin-bottom-11 {
  margin-bottom: 11px; }

.margin-bottom-12 {
  margin-bottom: 12px; }

.margin-bottom-13 {
  margin-bottom: 13px; }

.margin-bottom-14 {
  margin-bottom: 14px; }

.margin-bottom-15 {
  margin-bottom: 15px; }

.margin-bottom-16 {
  margin-bottom: 16px; }

.margin-bottom-17 {
  margin-bottom: 17px; }

.margin-bottom-18 {
  margin-bottom: 18px; }

.margin-bottom-19 {
  margin-bottom: 19px; }

.margin-bottom-20 {
  margin-bottom: 20px; }

.margin-bottom-21 {
  margin-bottom: 21px; }

.margin-bottom-22 {
  margin-bottom: 22px; }

.margin-bottom-23 {
  margin-bottom: 23px; }

.margin-bottom-24 {
  margin-bottom: 24px; }

.margin-bottom-25 {
  margin-bottom: 25px; }

.margin-bottom-26 {
  margin-bottom: 26px; }

.margin-bottom-27 {
  margin-bottom: 27px; }

.margin-bottom-28 {
  margin-bottom: 28px; }

.margin-bottom-29 {
  margin-bottom: 29px; }

.margin-bottom-30 {
  margin-bottom: 30px; }

.margin-bottom-31 {
  margin-bottom: 31px; }

.margin-bottom-32 {
  margin-bottom: 32px; }

.margin-bottom-33 {
  margin-bottom: 33px; }

.margin-bottom-34 {
  margin-bottom: 34px; }

.margin-bottom-35 {
  margin-bottom: 35px; }

.margin-bottom-36 {
  margin-bottom: 36px; }

.margin-bottom-37 {
  margin-bottom: 37px; }

.margin-bottom-38 {
  margin-bottom: 38px; }

.margin-bottom-39 {
  margin-bottom: 39px; }

.margin-bottom-40 {
  margin-bottom: 40px; }

.margin-bottom-41 {
  margin-bottom: 41px; }

.margin-bottom-42 {
  margin-bottom: 42px; }

.margin-bottom-43 {
  margin-bottom: 43px; }

.margin-bottom-44 {
  margin-bottom: 44px; }

.margin-bottom-45 {
  margin-bottom: 45px; }

.margin-bottom-46 {
  margin-bottom: 46px; }

.margin-bottom-47 {
  margin-bottom: 47px; }

.margin-bottom-48 {
  margin-bottom: 48px; }

.margin-bottom-49 {
  margin-bottom: 49px; }

.margin-bottom-50 {
  margin-bottom: 50px; }

.margin-bottom-51 {
  margin-bottom: 51px; }

.margin-bottom-52 {
  margin-bottom: 52px; }

.margin-bottom-53 {
  margin-bottom: 53px; }

.margin-bottom-54 {
  margin-bottom: 54px; }

.margin-bottom-55 {
  margin-bottom: 55px; }

.margin-bottom-56 {
  margin-bottom: 56px; }

.margin-bottom-57 {
  margin-bottom: 57px; }

.margin-bottom-58 {
  margin-bottom: 58px; }

.margin-bottom-59 {
  margin-bottom: 59px; }

.margin-bottom-60 {
  margin-bottom: 60px; }

.margin-bottom-61 {
  margin-bottom: 61px; }

.margin-bottom-62 {
  margin-bottom: 62px; }

.margin-bottom-63 {
  margin-bottom: 63px; }

.margin-bottom-64 {
  margin-bottom: 64px; }

.margin-bottom-65 {
  margin-bottom: 65px; }

.margin-bottom-66 {
  margin-bottom: 66px; }

.margin-bottom-67 {
  margin-bottom: 67px; }

.margin-bottom-68 {
  margin-bottom: 68px; }

.margin-bottom-69 {
  margin-bottom: 69px; }

.margin-bottom-70 {
  margin-bottom: 70px; }

.margin-bottom-71 {
  margin-bottom: 71px; }

.margin-bottom-72 {
  margin-bottom: 72px; }

.margin-bottom-73 {
  margin-bottom: 73px; }

.margin-bottom-74 {
  margin-bottom: 74px; }

.margin-bottom-75 {
  margin-bottom: 75px; }

.margin-bottom-76 {
  margin-bottom: 76px; }

.margin-bottom-77 {
  margin-bottom: 77px; }

.margin-bottom-78 {
  margin-bottom: 78px; }

.margin-bottom-79 {
  margin-bottom: 79px; }

.margin-bottom-80 {
  margin-bottom: 80px; }

.margin-bottom-81 {
  margin-bottom: 81px; }

.margin-bottom-82 {
  margin-bottom: 82px; }

.margin-bottom-83 {
  margin-bottom: 83px; }

.margin-bottom-84 {
  margin-bottom: 84px; }

.margin-bottom-85 {
  margin-bottom: 85px; }

.margin-bottom-86 {
  margin-bottom: 86px; }

.margin-bottom-87 {
  margin-bottom: 87px; }

.margin-bottom-88 {
  margin-bottom: 88px; }

.margin-bottom-89 {
  margin-bottom: 89px; }

.margin-bottom-90 {
  margin-bottom: 90px; }

.margin-bottom-91 {
  margin-bottom: 91px; }

.margin-bottom-92 {
  margin-bottom: 92px; }

.margin-bottom-93 {
  margin-bottom: 93px; }

.margin-bottom-94 {
  margin-bottom: 94px; }

.margin-bottom-95 {
  margin-bottom: 95px; }

.margin-bottom-96 {
  margin-bottom: 96px; }

.margin-bottom-97 {
  margin-bottom: 97px; }

.margin-bottom-98 {
  margin-bottom: 98px; }

.margin-bottom-99 {
  margin-bottom: 99px; }

.margin-left-1 {
  margin-left: 1px; }

.margin-left-2 {
  margin-left: 2px; }

.margin-left-3 {
  margin-left: 3px; }

.margin-left-4 {
  margin-left: 4px; }

.margin-left-5 {
  margin-left: 5px; }

.margin-left-6 {
  margin-left: 6px; }

.margin-left-7 {
  margin-left: 7px; }

.margin-left-8 {
  margin-left: 8px; }

.margin-left-9 {
  margin-left: 9px; }

.margin-left-10 {
  margin-left: 10px; }

.margin-left-11 {
  margin-left: 11px; }

.margin-left-12 {
  margin-left: 12px; }

.margin-left-13 {
  margin-left: 13px; }

.margin-left-14 {
  margin-left: 14px; }

.margin-left-15 {
  margin-left: 15px; }

.margin-left-16 {
  margin-left: 16px; }

.margin-left-17 {
  margin-left: 17px; }

.margin-left-18 {
  margin-left: 18px; }

.margin-left-19 {
  margin-left: 19px; }

.margin-left-20 {
  margin-left: 20px; }

.margin-left-21 {
  margin-left: 21px; }

.margin-left-22 {
  margin-left: 22px; }

.margin-left-23 {
  margin-left: 23px; }

.margin-left-24 {
  margin-left: 24px; }

.margin-left-25 {
  margin-left: 25px; }

.margin-left-26 {
  margin-left: 26px; }

.margin-left-27 {
  margin-left: 27px; }

.margin-left-28 {
  margin-left: 28px; }

.margin-left-29 {
  margin-left: 29px; }

.margin-left-30 {
  margin-left: 30px; }

.margin-left-31 {
  margin-left: 31px; }

.margin-left-32 {
  margin-left: 32px; }

.margin-left-33 {
  margin-left: 33px; }

.margin-left-34 {
  margin-left: 34px; }

.margin-left-35 {
  margin-left: 35px; }

.margin-left-36 {
  margin-left: 36px; }

.margin-left-37 {
  margin-left: 37px; }

.margin-left-38 {
  margin-left: 38px; }

.margin-left-39 {
  margin-left: 39px; }

.margin-left-40 {
  margin-left: 40px; }

.margin-left-41 {
  margin-left: 41px; }

.margin-left-42 {
  margin-left: 42px; }

.margin-left-43 {
  margin-left: 43px; }

.margin-left-44 {
  margin-left: 44px; }

.margin-left-45 {
  margin-left: 45px; }

.margin-left-46 {
  margin-left: 46px; }

.margin-left-47 {
  margin-left: 47px; }

.margin-left-48 {
  margin-left: 48px; }

.margin-left-49 {
  margin-left: 49px; }

.margin-left-50 {
  margin-left: 50px; }

.margin-left-51 {
  margin-left: 51px; }

.margin-left-52 {
  margin-left: 52px; }

.margin-left-53 {
  margin-left: 53px; }

.margin-left-54 {
  margin-left: 54px; }

.margin-left-55 {
  margin-left: 55px; }

.margin-left-56 {
  margin-left: 56px; }

.margin-left-57 {
  margin-left: 57px; }

.margin-left-58 {
  margin-left: 58px; }

.margin-left-59 {
  margin-left: 59px; }

.margin-left-60 {
  margin-left: 60px; }

.margin-left-61 {
  margin-left: 61px; }

.margin-left-62 {
  margin-left: 62px; }

.margin-left-63 {
  margin-left: 63px; }

.margin-left-64 {
  margin-left: 64px; }

.margin-left-65 {
  margin-left: 65px; }

.margin-left-66 {
  margin-left: 66px; }

.margin-left-67 {
  margin-left: 67px; }

.margin-left-68 {
  margin-left: 68px; }

.margin-left-69 {
  margin-left: 69px; }

.margin-left-70 {
  margin-left: 70px; }

.margin-left-71 {
  margin-left: 71px; }

.margin-left-72 {
  margin-left: 72px; }

.margin-left-73 {
  margin-left: 73px; }

.margin-left-74 {
  margin-left: 74px; }

.margin-left-75 {
  margin-left: 75px; }

.margin-left-76 {
  margin-left: 76px; }

.margin-left-77 {
  margin-left: 77px; }

.margin-left-78 {
  margin-left: 78px; }

.margin-left-79 {
  margin-left: 79px; }

.margin-left-80 {
  margin-left: 80px; }

.margin-left-81 {
  margin-left: 81px; }

.margin-left-82 {
  margin-left: 82px; }

.margin-left-83 {
  margin-left: 83px; }

.margin-left-84 {
  margin-left: 84px; }

.margin-left-85 {
  margin-left: 85px; }

.margin-left-86 {
  margin-left: 86px; }

.margin-left-87 {
  margin-left: 87px; }

.margin-left-88 {
  margin-left: 88px; }

.margin-left-89 {
  margin-left: 89px; }

.margin-left-90 {
  margin-left: 90px; }

.margin-left-91 {
  margin-left: 91px; }

.margin-left-92 {
  margin-left: 92px; }

.margin-left-93 {
  margin-left: 93px; }

.margin-left-94 {
  margin-left: 94px; }

.margin-left-95 {
  margin-left: 95px; }

.margin-left-96 {
  margin-left: 96px; }

.margin-left-97 {
  margin-left: 97px; }

.margin-left-98 {
  margin-left: 98px; }

.margin-left-99 {
  margin-left: 99px; }

.margin-right-1 {
  margin-right: 1px; }

.margin-right-2 {
  margin-right: 2px; }

.margin-right-3 {
  margin-right: 3px; }

.margin-right-4 {
  margin-right: 4px; }

.margin-right-5 {
  margin-right: 5px; }

.margin-right-6 {
  margin-right: 6px; }

.margin-right-7 {
  margin-right: 7px; }

.margin-right-8 {
  margin-right: 8px; }

.margin-right-9 {
  margin-right: 9px; }

.margin-right-10 {
  margin-right: 10px; }

.margin-right-11 {
  margin-right: 11px; }

.margin-right-12 {
  margin-right: 12px; }

.margin-right-13 {
  margin-right: 13px; }

.margin-right-14 {
  margin-right: 14px; }

.margin-right-15 {
  margin-right: 15px; }

.margin-right-16 {
  margin-right: 16px; }

.margin-right-17 {
  margin-right: 17px; }

.margin-right-18 {
  margin-right: 18px; }

.margin-right-19 {
  margin-right: 19px; }

.margin-right-20 {
  margin-right: 20px; }

.margin-right-21 {
  margin-right: 21px; }

.margin-right-22 {
  margin-right: 22px; }

.margin-right-23 {
  margin-right: 23px; }

.margin-right-24 {
  margin-right: 24px; }

.margin-right-25 {
  margin-right: 25px; }

.margin-right-26 {
  margin-right: 26px; }

.margin-right-27 {
  margin-right: 27px; }

.margin-right-28 {
  margin-right: 28px; }

.margin-right-29 {
  margin-right: 29px; }

.margin-right-30 {
  margin-right: 30px; }

.margin-right-31 {
  margin-right: 31px; }

.margin-right-32 {
  margin-right: 32px; }

.margin-right-33 {
  margin-right: 33px; }

.margin-right-34 {
  margin-right: 34px; }

.margin-right-35 {
  margin-right: 35px; }

.margin-right-36 {
  margin-right: 36px; }

.margin-right-37 {
  margin-right: 37px; }

.margin-right-38 {
  margin-right: 38px; }

.margin-right-39 {
  margin-right: 39px; }

.margin-right-40 {
  margin-right: 40px; }

.margin-right-41 {
  margin-right: 41px; }

.margin-right-42 {
  margin-right: 42px; }

.margin-right-43 {
  margin-right: 43px; }

.margin-right-44 {
  margin-right: 44px; }

.margin-right-45 {
  margin-right: 45px; }

.margin-right-46 {
  margin-right: 46px; }

.margin-right-47 {
  margin-right: 47px; }

.margin-right-48 {
  margin-right: 48px; }

.margin-right-49 {
  margin-right: 49px; }

.margin-right-50 {
  margin-right: 50px; }

.margin-right-51 {
  margin-right: 51px; }

.margin-right-52 {
  margin-right: 52px; }

.margin-right-53 {
  margin-right: 53px; }

.margin-right-54 {
  margin-right: 54px; }

.margin-right-55 {
  margin-right: 55px; }

.margin-right-56 {
  margin-right: 56px; }

.margin-right-57 {
  margin-right: 57px; }

.margin-right-58 {
  margin-right: 58px; }

.margin-right-59 {
  margin-right: 59px; }

.margin-right-60 {
  margin-right: 60px; }

.margin-right-61 {
  margin-right: 61px; }

.margin-right-62 {
  margin-right: 62px; }

.margin-right-63 {
  margin-right: 63px; }

.margin-right-64 {
  margin-right: 64px; }

.margin-right-65 {
  margin-right: 65px; }

.margin-right-66 {
  margin-right: 66px; }

.margin-right-67 {
  margin-right: 67px; }

.margin-right-68 {
  margin-right: 68px; }

.margin-right-69 {
  margin-right: 69px; }

.margin-right-70 {
  margin-right: 70px; }

.margin-right-71 {
  margin-right: 71px; }

.margin-right-72 {
  margin-right: 72px; }

.margin-right-73 {
  margin-right: 73px; }

.margin-right-74 {
  margin-right: 74px; }

.margin-right-75 {
  margin-right: 75px; }

.margin-right-76 {
  margin-right: 76px; }

.margin-right-77 {
  margin-right: 77px; }

.margin-right-78 {
  margin-right: 78px; }

.margin-right-79 {
  margin-right: 79px; }

.margin-right-80 {
  margin-right: 80px; }

.margin-right-81 {
  margin-right: 81px; }

.margin-right-82 {
  margin-right: 82px; }

.margin-right-83 {
  margin-right: 83px; }

.margin-right-84 {
  margin-right: 84px; }

.margin-right-85 {
  margin-right: 85px; }

.margin-right-86 {
  margin-right: 86px; }

.margin-right-87 {
  margin-right: 87px; }

.margin-right-88 {
  margin-right: 88px; }

.margin-right-89 {
  margin-right: 89px; }

.margin-right-90 {
  margin-right: 90px; }

.margin-right-91 {
  margin-right: 91px; }

.margin-right-92 {
  margin-right: 92px; }

.margin-right-93 {
  margin-right: 93px; }

.margin-right-94 {
  margin-right: 94px; }

.margin-right-95 {
  margin-right: 95px; }

.margin-right-96 {
  margin-right: 96px; }

.margin-right-97 {
  margin-right: 97px; }

.margin-right-98 {
  margin-right: 98px; }

.margin-right-99 {
  margin-right: 99px; }

* {
  margin: 0;
  padding: 0; }

@font-face {
  font-family: "Bebas Neue W01 Regular";
  src: url(/static/media/3d9ec9a9-76a2-4cfe-b376-76e7340c3b50.5d94526b.eot?#iefix);
  src: url(/static/media/3d9ec9a9-76a2-4cfe-b376-76e7340c3b50.5d94526b.eot?#iefix) format("eot"), url(/static/media/e0d6f852-5401-4bbf-9672-47a50c5c87c6.12dae993.woff2) format("woff2"), url(/static/media/7fedd582-6ae3-4850-be2f-4acae2e74fa5.9a62fff5.woff) format("woff"), url(/static/media/d6e08ef3-40db-4ac3-82df-f062f55a72f5.cf034e78.ttf) format("truetype"); }

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #000000;
  color: #fff;
  font-family: "Bebas Neue W01 Regular"; }

.margin-0 {
  margin: 0;
  padding: 0; }

