@import "../../sass/colors";

.btn-primary {
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: bold;
  background: linear-gradient(290deg, $yellow2, $yellow);
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 30px;
  padding-right: 30px;
  color: #000;
  text-decoration: none;
  width: 220px;
  height: 200px;
  border-radius: 30px;
}

.hunter-logo {
  width: 150px;
  height: auto;
}
.social-media {
  display: block;
  margin: 20px;
  width: 30px;
  height: auto;
  @media screen and (max-width: 400px) {
    display: inline-block;
  }
}
.icon-facebook {
  width: 20px;
}
.footer-p {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 100;
  letter-spacing: 0px;
  text-align: center;
  @media screen and (max-width: 600px) {
    margin-left: 0;
  }
}
.price-footer {
  font-size: 80px;
  letter-spacing: -1px;
}
#zoid-paypal-button-cef908d29b {
  background: red;
}

.p-xs {
  margin-top: 10px;
  text-align: center;
  font-size: 40px;
  &:before {
    position: absolute;
    content: "----------";
    transform: rotate(15deg);
    color: red;
  }
}
.paypal-button {
  margin-top: 45px;
}
.button-order-now {
  background-color: $yellow;
  color: #fff;
  font-size: 25px;
  padding: 15px 50px 15px 50px;
  text-decoration: none;
}
.txt-small{
  color: #fff;
}